import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';
import MicroCategoryRow from './ui/MicroCategoryRow';

class MicroProductCategoriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }
  
  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    API.getMicroEstablishmentCategories()
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
          <Header text="Categorías" subtitle="Agrega categorías para tus productos"/>
          <Button circular icon primary as={Link} to="/micro/product-categories/new">
            <Icon name="plus" />
          </Button> 
        </div>
        {this.state.categories.map(category => 
          <MicroCategoryRow category={category}/>
        )}
        
        
      </div>
    )
  }
}
export default withRouter(MicroProductCategoriesPage);