import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Button, Card, Statistic } from 'semantic-ui-react';
import { addDays, subDays } from 'date-fns';
import Header from 'components/Header';
import MicroClosedBillCard from './ui/MicroClosedBillCard';
import API from 'utils/api';
import formatter from 'utils/formatter';
import { format, isTomorrow, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';
import MicroSalesByWeek from './ui/MicroSalesByWeek';
import MicroSalesByMonth from './ui/MicroSalesByMonth';
import MicroSalesByTotal from './ui/MicroSalesByTotal';

class MicroClosedBillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeViews: {
        week: false,
        month: false,
        total: true
      }
    };
  }

  toggleView = (view) => {
    this.setState(prevState => ({
      activeViews: {
        ...prevState.activeViews,
        [view]: !prevState.activeViews[view]
      }
    }));
  }

  render() {
    const { activeViews } = this.state;
    
    const buttonStyle = {
      width: '100%', 
      marginTop: '1em',
      textAlign: 'left',     // Alinear el texto del botón a la izquierda
      justifyContent: 'flex-start'  // Alinear el contenido del botón a la izquierda
    };
    
    return (
      <div>
        <h2 style={{marginTop:'1em', textAlign:'center'}}>REPORTES DE VENTAS</h2>
        <Button 
          icon 
          labelPosition='right' 
          style={buttonStyle}
          onClick={() => this.toggleView('week')}
          active={activeViews.week}
        >
          Ventas por Semana
          <Icon name={activeViews.week ? 'chevron up' : 'chevron down'} />
        </Button>

        {activeViews.week && <MicroSalesByWeek />}

        <Button 
          icon 
          labelPosition='right' 
          style={buttonStyle}
          onClick={() => this.toggleView('month')}
          active={activeViews.month}
        >
          Ventas por Mes
          <Icon name={activeViews.month ? 'chevron up' : 'chevron down'} />
        </Button>

        {activeViews.month && <MicroSalesByMonth />}

        <Button 
          icon 
          labelPosition='right' 
          style={buttonStyle}
          onClick={() => this.toggleView('total')}
          active={activeViews.total}
        >
          Ventas Totales
          <Icon name={activeViews.total ? 'chevron up' : 'chevron down'} />
        </Button>

        {activeViews.total && <MicroSalesByTotal />}
      </div>
    )
  }
}

export default withRouter(MicroClosedBillsPage);