import React from 'react';
import { Icon, Image, Divider } from 'semantic-ui-react';
import businesOwnerImage from 'images/business-owner-large.png';
import LoginButtons from 'components/LoginButtons';
import NavPublic from 'ui/NavPublic';
import MoreInfo from 'components/MoreInfo';
import infoSegments from 'components/MoreInfo';

class PricingPage extends React.Component {

  render() {
    return (
      <div style={{ textAlign: 'center', paddingBottom: '2em', maxWidth: '400px' }}>
        <NavPublic />
        <div style={{ marginBottom: '1em' }}>
          <div style={{ margin: '1em 0' }}>
            <h1 style={{ marginBottom: '2.5px' }}>
              Nuestros planes
            </h1>
            <p style={{ color: 'gray' }}>
              Descubre las opciones de suscripción<br/>
              para tu negocio de comida.
            </p>
          </div>
        </div>

        <Divider style={{ margin: '2em 4em' }} />
        
        <div style={{margin: '2em 1em'}}>
          <h5>Plan Básico: $100.00 MXN/mes</h5>
          <p>(aprox. $4.99 USD/mes)</p>
          <p>Incluye: Acceso a todas las funciones básicas de la plataforma.</p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />
        <div style={{ margin: '2em 1em' }}>                    
          <p style={{ color: 'gray', fontSize: '0.8em' }}>Registrate sin añadir forma de pago y pruebalo por 15 días</p>
          <LoginButtons />
        </div>
      </div>
    )
  }
}

export default PricingPage;