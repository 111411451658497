import React, { useState } from 'react';
import { Button, Icon, Card } from 'semantic-ui-react';

export default function MicroBillProducts({ bill, products, toggleProductReadyFn }) {
  const [pendingOrdersText, setPendingOrdersText] = useState('Sin ordenes pendientes');

  const toggleProductReadyStatus = (product) => {
    toggleProductReadyFn(product.id);
  }
  
  if (!products || products.length === 0) { return null }

  return (
    <Card fluid style={{ marginBottom: '2em' }}>
      <Card.Content>
        <Card.Description>
          { products.map((product) => {
            const textDecorationStyle = product.ready_at ? 'line-through' : 'none';
            const textColorStyle = product.ready_at ? 'darkgray' : '';
            
            return (
              <div key={product.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                <p style={{ marginBottom: 0, color: textColorStyle, textDecoration: textDecorationStyle }}>
                  <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                    { product.quantity }
                  </b>
                  <span style={{ fontSize: '18px' }}>
                    { product.product.name }
                  </span>
                  { (product.ready_at !== null) &&
                    <span style={{ marginLeft: '0.5em' }}>
                      <Icon name="check" size="large" color="green" />
                    </span>
                  }
                </p>
                <span style={{ paddingLeft: '1em' }}>
                  { (product.ready_at === null) ? (
                    <Button
                      size="tiny"
                      icon
                      circular
                      onClick={ () => toggleProductReadyStatus(product) }
                    >
                      <Icon name="check" />
                    </Button>
                  ) : (
                    <Button
                      size="tiny"
                      icon
                      circular
                      onClick={ () => toggleProductReadyStatus(product) }
                    >
                      <Icon name="close" />
                    </Button>
                  )}
                </span>
              </div>
            )
          })}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}