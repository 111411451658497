import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon, Segment } from 'semantic-ui-react';

// Message to notify the user of something important
// 
function Message({ headerText, bodyText, iconName, children}) {
  return (
    <Segment placeholder>
      <Header textAlign='center' icon={iconName ? true : false}>
        { iconName &&
          <Icon name={iconName} />
        }
        
        { headerText }
      </Header>
      
      { bodyText &&
        <p style={{ padding: '0 2em', textAlign: 'center' }}>
          { bodyText }
        </p>
      }
      
      { children }
    </Segment>
  )
}

Message.propTypes = {
  headerText: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  children: PropTypes.node
}

export default Message;