const tokenKey = 'restauranterotkn';

const authHelpers = {
  login: (token) => {
    if (token) {
      window.localStorage.setItem(tokenKey, token);
    }
  },
  
  logout: () => {
    window.localStorage.removeItem(tokenKey);
  },
  
  getToken: () => {
    return window.localStorage.getItem(tokenKey);
  },
  
  getAuthHeaders: () => {
    const token = authHelpers.getToken();
    return {
      headers: { 'Authorization': `Token ${token}` } 
    }
  }
}

export default authHelpers;