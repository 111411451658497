import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Header, Icon, Label } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import formatter from 'utils/formatter';

export default function MicroOpenBillCard({ bill }) {
  const isMoreThanOneMinuteAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = (now - date) / 1000;
    return differenceInSeconds > 60;
  }
  
  let remaining = 0
  let ready = 0

  bill.orders.forEach((order) => {
    if (order.ready_at) {
      ready += (1*order.quantity)
    } else {
      remaining += (1*order.quantity)
    }
  });

  return (
    <Card
      fluid
      key={bill.id}
      as={Link}
      to={`/micro/bills/${bill.id}`}
      style={{ marginBottom: '1.5em' }}
      color={ remaining === 0 ? 'green' : 'gray' }
    >
      <Card.Content
        style={{ cursor: 'pointer' }}
      >
        <Card.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em' }}>
            <span style={{ fontSize: '22px' }}>
              { formatter.currency(bill.total) }
            </span>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>  
              { (remaining === 0) && (bill.total > 0) && (
                <Label color="green" icon style={{marginRight:'1em'}}>
                  <Icon name="check" style={{ margin: 0 }} />
                </Label> 
              )}

              { bill.paid_at ? (
                <Header as='h5' color='green' style={{margin:0}}>
                  PAGADO
                </Header>
              ) : (
                <Header as='h5' color='red' style={{margin:0}}>
                  SIN PAGAR
                </Header>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Meta>
          <span style={{ color: '#444' }}>
          { bill.note }
          </span>
        </Card.Meta>
        <Card.Description>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
            <p style={{ marginBottom: 0, fontSize: '16px' }}>
              { remaining > 0 && (
                <b style={{ marginRight: '5px' }}>
                  { remaining } { remaining === 1 ? 'producto pendiente' : 'productos pendientes' }
                </b>
              )}
              { ready > 0 && (
                <span>
                  | { ready } { ready === 1 ? 'producto listo' : 'productos listos' }
                </span>
              )}
            </p>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            { isMoreThanOneMinuteAgo(bill.created_at) ? (
              <>
                <Icon name="clock outline" size='large' />
                <TimeAgo
                  datetime={bill.created_at}
                  locale="es"
                />
              </>
            ) : (
              <span style={{ marginRight: '3px' }}>
                Recién ordenado
              </span>
            )}
          </div>
          <div>
            { bill.name &&
              <>
                <span>
                  { bill.name.substring(0,15).toUpperCase() }
                </span>
                <span style={{marginLeft:'1em', marginRight: '1em'}}>
                  /
                </span>
              </>
            }            
            <span>#{bill.id}</span>            
          </div>
        </div>
      </Card.Content>
    </Card>
  )
}