import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, MenuMenu, Icon } from 'semantic-ui-react';

export default function NavPublic(props){

    const [showMenu, setShowMenu] = useState(false);
    const [activeItem, setActiveItem] = useState(window.location.pathname);
    const history = useHistory();

    const handleItemClick = (e, { name, url }) => {
        setShowMenu(false);
        setActiveItem(url);
        history.push(url);
    };

    const toggleShowMenu = () => {
        setShowMenu(!showMenu);
    };  

    // Add links here with { name, url }
    const publicLinks = [
        { name: 'Principal', url: '/' },
        { name: 'Suscripción', url: '/precio' },
        { name: 'Iniciar Sesión', url: '/login' }
    ]; 

    return(
        <div>
            <Menu attached borderless compact inverted size="small" color="blue">
                <Menu.Item position='right'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '1em' }}>
                        <a href="/micro/bills" style={{ textDecoration: 'none', color: 'white' }}>RESTAURANTERO</a>
                        </span>
                        <Icon
                        name={showMenu ? 'close' : 'bars'}
                        size="large"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleShowMenu}
                        />
                    </div>
                    </Menu.Item>
            </Menu>
            {showMenu && (
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'fixed', zIndex: 1, width: '100%', height: '100%', backgroundColor: '#ffffffa6'}} onClick={toggleShowMenu}>
                        <Menu vertical style={{ position: 'fixed', right: 0, textAlign: 'right', width: '60%', borderRadius: '0 0 0 0.28571429rem'}}>
                            <MenuItem>
                                <MenuMenu>
                                    {publicLinks.map((link, i) => (
                                        <MenuItem
                                            style={{ fontSize: '1em', padding: '1em'}}
                                            key={i}
                                            active={activeItem === link.url}
                                            name={link.name}
                                            url={link.url}                                        
                                            onClick={(e) => handleItemClick(e, link)}
                                        />
                                    ))}
                                </MenuMenu>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            )}
        </div>
    )
}