import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from 'components/Header';
import { Form, Input } from 'semantic-ui-react';
import CancelGoButtons from 'components/CancelGoButtons';
import API from 'utils/api';

class MicroProductCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      categories: [],
      product_category_id: props.product_category_id || null,
      name: props.name || '',
      price: props.price || '',
      desc: props.desc || ''
    };
  }

  handleName = (event) => {
    this.setState({ name: event.target.value }, this.validate);
  }
  
  validate = () => {
    let valid = true;
    if (this.state.name === '') { valid = false; }
    this.setState({ valid });
  }

  createProductCategory = () => {
    if (this.state.valid) {
      
      const params = {
        name: this.state.name
      };

      API.createMicroProductCategory(params)
        .then((response) => {
          this.props.history.push('/micro/categories');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div>
          <Header text={this.props.editing ? 'Editar categoría' : 'Agregar categoría'} subtitle="Agrega una nueva categoría" />

          <Form as='div'>
            <Form.Field>
              <label>Nombre de la categoría</label>
              <Input
                value={this.state.name}
                onChange={this.handleName}
                placeholder="Ejemplo: Bebidas"
              />
            </Form.Field>
          
            <CancelGoButtons
              cancelFn={ () => this.props.history.push('/micro/categories') }
              goText={'crear categoría' }
              goFn={this.createProductCategory}
              goEnabled={this.state.valid}
            />
          </Form>
        </div>
    )
  }
}

export default withRouter(MicroProductCategoryForm);