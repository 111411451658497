import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { OwnerRoute, MicroRoute } from 'utils/routes';

import HomePage from 'pages/HomePage';
import AuthPage from 'pages/AuthPage';
import MenuPage from 'pages/MenuPage';
import PricingPage from 'pages/PricingPage';
import MicroNewBillPage             from 'pages/micro/MicroNewBillPage';
import MicroBillsPage               from 'pages/micro/MicroBillsPage';
import MicroSalesPage               from 'pages/micro/MicroSalesPage';
import MicroBillPage                from 'pages/micro/MicroBillPage';
import MicroProductsPage            from 'pages/micro/MicroProductsPage';
import MicroProductPage             from 'pages/micro/MicroProductPage';
import MicroProductForm             from 'pages/micro/ui/MicroProductForm';
import MicroProductCategoryPage     from 'pages/micro/MicroProductCategoryPage';
import MicroAccountPage             from 'pages/micro/MicroAccountPage';
import MicroSuscriptionConfirmed      from 'pages/micro/ui/SuscriptionConfirmed';
import OwnerSalesReportsPage        from 'pages/owner/OwnerSalesReportsPage';
import OwnerEstablishmentSalesReportPage from 'pages/owner/OwnerEstablishmentSalesReportPage';

import LogoutPage from 'pages/LogoutPage';
import LoginPage from 'pages/LoginPage';
import MicroProductCategoriesPage from 'pages/micro/MicroProductCategoriesPage';
import MicroExpensesPage from 'pages/micro/MicroExpensesPage';
import MicroExpensesForm from 'pages/micro/ui/MicroExpensesForm';
import MicroClosedBillsPage from 'pages/micro/MicroClosedBillsPage';


function App(props) {
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  return (
  
    <BrowserRouter>
      <div id="app" style={appStyle}>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/precio" exact>
            <PricingPage />
          </Route>

          <Route path="/login" exact>
            <LoginPage />
          </Route>
          
          <Route path="/logout" exact>
            <LogoutPage />
          </Route>

          <Route path="/establishments/:uuid" exact>
            <MenuPage />
          </Route>

          <Route path="/auth/:user" exact>
            <AuthPage />
          </Route>

          <MicroRoute path="/micro/account" exact>
            <MicroAccountPage />
          </MicroRoute>

          <MicroRoute path="/micro/account/suscription_confirmed" exact>
            <MicroSuscriptionConfirmed />
          </MicroRoute>

          <MicroRoute path="/micro/bills/new" exact>
            <MicroNewBillPage />
          </MicroRoute>

          <MicroRoute path="/micro/closed-bills" exact>
            <MicroClosedBillsPage />
          </MicroRoute>
          
          <MicroRoute path="/micro/sales-reports" exact>
            <MicroSalesPage />
          </MicroRoute>

          <MicroRoute path="/micro/bills" exact>
            <MicroBillsPage />
          </MicroRoute>

          <MicroRoute path="/micro/bills/:billId" exact>
            <MicroBillPage />
          </MicroRoute>

          <MicroRoute path="/micro/categories" exact>
            <MicroProductCategoriesPage />
          </MicroRoute>

          <MicroRoute path="/micro/products/new" exact>
            <MicroProductForm />
          </MicroRoute>

          <MicroRoute path="/micro/products" exact>
            <MicroProductsPage />
          </MicroRoute>

          <MicroRoute path="/micro/products/:productId" exact>
            <MicroProductPage />
          </MicroRoute>

          <MicroRoute path="/micro/product-categories/new" exact>
            <MicroProductCategoryPage />
          </MicroRoute>

          <MicroRoute path="/micro/expenses" exact>
            <MicroExpensesPage />
          </MicroRoute>

          <MicroRoute path="/micro/expenses/new" exact>
            <MicroExpensesForm />
          </MicroRoute>

          
          <OwnerRoute path="/owner/sales" exact>
            <OwnerSalesReportsPage />
          </OwnerRoute>

          <OwnerRoute path="/owner/sales/:establishmentId" exact>
            <OwnerEstablishmentSalesReportPage />
          </OwnerRoute>
        </Switch>
      </div>
    </BrowserRouter>
  )  
}

export default App;