import React, { useState } from 'react';
import { List, Grid, Card } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';

export default function MicroBillSummary({ bill, orders, cancelFn, billPaidFn }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (showConfirmation) {
    const formattedTotal = formatter.currency(bill.total);
    return (
      <div style={{ marginTop: '1em' }}>
        <Message headerText="confirmar cuenta pagada">
          <p style={{ textAlign: 'center', fontSize: '16px' }}>
            Confirma que la cuenta <b>#{bill.id}</b>
            <br/>
            {bill.note && (
              <>
                para <b>{bill.note}</b>
                <br/>
              </>
            )}
            ha sido pagada por
            <br/>
            <b>{formattedTotal}</b>
          </p>

          <CancelGoButtons
            cancelFn={cancelFn}
            goText="confirmar cuenta pagada"
            goEnabled={true}
            goFn={billPaidFn}
            warning={true}
          />
        </Message>
      </div>
    )
  }

  const combinedOrders = orders.reduce((acc, order) => {
    const existingOrder = acc.find(o => o.product.name === order.product.name);
    const totalPrice = order.product.price * order.quantity;

    if (existingOrder) {
      existingOrder.quantity += order.quantity;
      existingOrder.totalPrice += totalPrice;
    } else {
      acc.push({ ...order, totalPrice });
    }

    return acc;
  }, []);

  return (
    <div>
      <Header text={bill.name ? bill.name : "Order #" + bill.id} subtitle={bill.note} inlineSubtitle={true} />

      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            <List.Item>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <strong>Producto</strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <strong>Cantidad</strong>
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right">
                    <strong>PU</strong>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right">
                    <strong>Total</strong>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>
            { combinedOrders.map((order) => 
              <List.Item key={order.id}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6} verticalAlign="top">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        { order.product.name }
                      </p>
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="top">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        { order.quantity }
                      </p>
                    </Grid.Column>
                    <Grid.Column width={3} verticalAlign="top" textAlign="right">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        { formatter.currency(order.product.price) }
                      </p>
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="top" textAlign="right">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        {formatter.currency(order.totalPrice)}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
      
      <Card fluid>
        <Card.Header>
          <List relaxed>
            <List.Item>
              <div style={{ textAlign: 'right', padding: '1em', fontSize: '20px' }}>
                Total a pagar
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                { formatter.currency(bill.total) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Header>
      </Card>
      
      <CancelGoButtons
        cancelFn={cancelFn}
        goText="cobrar cuenta"
        goFn={ () => setShowConfirmation(true) }
        goEnabled={true}
      />
    </div>
  )
}