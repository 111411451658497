import React from "react";

export default function SuscriptionConfirmed() {
    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Gracias por contratar el Plan Básico!</h1>
            <p>Una vez acreditado su pago, el plan se reflejará en su cuenta.</p>
            <p>Aquí hay algunas características que podrás disfrutar:</p>
            <ul>
                <li>Acceso a contenido básico</li>
                <li>Soporte de la comunidad</li>
                <li>Boletines mensuales</li>
            </ul>
            <p>Esperamos que disfrutes tu experiencia con nosotros.</p>
        </div>
    );
}
