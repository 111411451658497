import React from 'react';
import { Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import 'moment/locale/es';

export default function SelectReportsDate({ initialDate, onChangeFn }) {
  if (!onChangeFn) {
    throw 'onChangeFn is required!';
  }

  const handleDateChange = (event, { name, value }) => {
    const parsedDate = moment(value, 'DD-MM-YYYY').locale('es');
    const convertedDate = parsedDate.toDate();
    onChangeFn(convertedDate);
  }

  const date = initialDate || new Date();
  const formattedDate = moment(date).format('DD-MM-YYYY');

  return (
    <div style={{ marginTop: '2em' }}>
      <h2>Selecciona una fecha para ver tus ventas totales:</h2>
      <Form>
        <DateInput
          inline
          name='date'
          value={formattedDate}
          localization="es"
          onChange={handleDateChange}
        />
      </Form>
    </div>   
  )
}