import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import authHelpers from 'utils/authHelpers';
import Nav from 'ui/Nav';
import PoweredBy from 'ui/PoweredBy';
import BannerFreeTrial from 'ui/BannerFreeTrial';

// Micro
export const MicroRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  
  if (token) {
    const component = React.cloneElement(children);
    
    // Add links here with { name, url }
    const links={      
      FINANZAS: [
        { name: 'Cuentas abiertas', url: '/micro/bills' },
        { name: 'Cuentas cerradas', url: '/micro/closed-bills' },
        { name: 'Ventas', url: '/micro/sales-reports' },
        { name: 'Gastos', url: '/micro/expenses' }
      ],
      ADMINISTRACIÓN: [        
        { name: 'Categorias', url: '/micro/categories' },
        { name: 'Productos', url: '/micro/products' }
      ],
      CUENTA: [
        { name: 'Mi cuenta', url: '/micro/account' },
        { name: 'Cerrar sesión', url: '/logout' }
      ]
    };

    return (
      <Route {...remainingProps}>
        <Nav links={links} />
        <Container style={{ flexGrow: 1 }}>
          { component }
        </Container>
        <PoweredBy />
        <BannerFreeTrial />
      </Route>
    )
  }
  
  return <Redirect to="/" />
}

// Owner
export const OwnerRoute = ({ children, ...remainingProps }) => {
  const user = authHelpers.getUser();
  const token = authHelpers.getToken();
  
  if (token) {
    if (user === 'owner') {
      const component = React.cloneElement(children);
      
      const links = [
        { name: 'ventas totales', url: '/owner/reports/sales' },
      ];

      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={{ flexGrow: 1 }}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}
