import React from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Grid, List, Button, Icon, Form, Input } from 'semantic-ui-react';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';
import MicroSearchableMenu from './ui/MicroSearchableMenu';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MicroNewBillPage extends React.Component {
  constructor(props) {
    super(props);
    this.noteInputRef = React.createRef();
    this.state = {
      billItems: [],
      note: '',
      total: 0,
      showNoteForm: false,
      showMenu: false,
      showConfirmation: false
    };
  }

  componentDidMount() {
    this.updateTotal();
  }

  handleNote = (event) => {
    this.setState({ note: event.target.value });
  }

  openBill = () => {
    this.setState({
      showNoteForm: false,
      showMenu: true
    });
  }

  updateTotal = () => {
    let total = 0.0;
    
    this.state.billItems.forEach((billItem) => {
      const orderTotal = billItem.product.price * billItem.quantity;
      total += orderTotal;
    });

    this.setState({ total });
  }

  addOrUpdateBillItem = (product, quantity) => {
    let isProductUpdate = false;
    this.setState((prevState) => {
      let newBillItems = prevState.billItems.map(billItem => {
        if(billItem.product.id === product.id){
          isProductUpdate = true;
          return({
            product,
            quantity
          });
        }

        return(billItem)
      })

      if(isProductUpdate){
        console.log("It is a product update returning updated billItems")
        console.log(newBillItems)
        return { billItems: newBillItems}  
      }

      console.log("Is not product update attempting to add new product to bill")
      const finalNewBillItems = [{ product, quantity }, ...newBillItems]
      console.log("Printing finalNewBill items")
      console.log(finalNewBillItems)
      
      return { billItems: finalNewBillItems}      
    }, this.updateTotal);
  }

  validateBill = () => {
    let valid = true;

    if (this.state.billItems.length === 0) {
      valid = false;
    }

    return valid
  }

  createBill = () => {
    const orderParams = this.state.billItems.reverse().map((billItem) => {
      return {
        product_id: billItem.product.id,
        quantity: billItem.quantity
      }
    })
    
    const params = {
      orders: orderParams
    };

    API.createMicroBill(params)
      .then((response) => {
        this.props.history.push('/micro/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {

    //calculate orderNumber based on existing products
    const orderNumber = this.state.billItems.length > 0 ? this.state.billItems.length : 1;


    console.log(orderNumber)

    // Get the bill's ID (customer name) (default)
    if (this.state.showNoteForm) {
      return (
        <div>
          <Header text="Cuenta nueva" subtitle="Agrega el nombre del cliente" />
          
          <Form as="div" onSubmit={ (e) => e.preventDefault() }>
            <Form.Field>
              <Input
                ref={this.noteInputRef}
                value={this.state.note}
                onChange={this.handleNote}
                placeholder="Ejemplo: Alex"
                style={{ fontSize: '18px' }}
              />
            </Form.Field>
            
            <CancelGoButtons
              cancelFn={ () => this.props.history.push('/micro/bills') }
              goText="abir cuenta"
              goFn={this.openBill}
              goEnabled={this.state.note.length > 0}
            />
          </Form>
        </div>
      )
    }

    // Display a searchable menu to choose products
    if (this.state.showMenu) {
      return (        
        <MicroSearchableMenu
          cancelFn={ () => this.setState({ showMenu: false }) }
          addOrUpdateBillItem={this.addOrUpdateBillItem}
          billItems={this.state.billItems}
        />        
      )
    }

    // Display Confirmation before create
    if (this.state.showConfirmation) {
      const formattedTotal = formatter.currency(this.state.total);;
      return (
        <div style={{ marginTop: '1em' }}>
          <Message headerText="confirmar nueva cuenta">
            <p style={{ textAlign: 'center', fontSize: '16px' }}>
              Confirma una nueva cuenta
              <br/>
              para <b>{this.state.note}</b>
              <br/>
              por <b>{formattedTotal}</b>
            </p>
  
            <CancelGoButtons
              cancelFn={ () => this.setState({ showConfirmation: false })}
              goText="crear cuenta nueva"
              goEnabled={true}
              goFn={this.createBill}
              warning={true}
            />
          </Message>
        </div>
      )
    }

    // Display Bill
    return (
      <div>
        <Header text="Cuenta nueva" subtitle="Agrega productos a la orden">
          <Button circular icon primary onClick={ () => this.setState({ showMenu: true }) }>
            <Icon name="plus" />
            <span style={{marginLeft:'0.5em'}}>Productos</span>
          </Button>
        </Header>
        <List divided>
          { this.state.billItems.map((billItem) =>
            <List.Item style={{ padding: '1em 0' }} key={billItem.product.id}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={11} verticalAlign="middle">
                    <p style={{ marginBottom: 0 }}>
                      <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                        { billItem.quantity }
                      </b>
                      <span style={{ fontSize: '18px' }}>
                        { billItem.product.name }
                      </span>
                    </p>
                    { (this.state.orderBeingEditedUUID !== billItem.product.id) &&
                      <p style={{ fontWeight: 'normal', color: 'gray' }}>
                        { billItem.notes }
                      </p>
                    }
                  </Grid.Column>
                  
                  <Grid.Column width={5} verticalAlign="middle" textAlign="right">
                    <div>
                      <Button icon circular color={billItem.quantity === 1 ? 'red' : 'grey'} onClick={ () => this.addOrUpdateBillItem(billItem.product, billItem.quantity - 1) }>
                        <Icon name="minus" />
                      </Button>
                      <Button icon circular color="grey" onClick={ () => this.addOrUpdateBillItem(billItem.product, billItem.quantity + 1) }>
                        <Icon name="plus" />
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>
          )}
        </List>

        <div>
          <p style={{ textAlign: 'right', fontSize: '20px' }}>
            <b>Total:</b> { formatter.currency(this.state.total) }
          </p>

        </div>
        
        <CancelGoButtons
          cancelFn={ () => this.props.history.push('/micro/bills') }
          goText="crear cuenta"
          goEnabled={ this.validateBill() }
          goFn={this.createBill}
        />
      </div>
    )
  }
}

export default withRouter(MicroNewBillPage);