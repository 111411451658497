import React, {  } from 'react';
import LoginButtons from 'components/LoginButtons';
import NavPublic from 'ui/NavPublic';

export default function LoginPage() {

    return (
        <>
            <NavPublic />
            <div style={{ display: 'flex', justifyContent: 'center'}}>
                <div>                
                    <div style={{ textAlign: 'center', paddingTop: '2em', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
                        <h1>Inicia sesión en Restaurantero</h1>
                        <p>Accede desde tu cuenta de google para continuar.</p>
                    </div>
                    <LoginButtons />
                </div>
        </div>
        </>
        
    );
}
