import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Grid, List, Button, Icon, Form, Input } from 'semantic-ui-react';

class MicroBillProductMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    
  }

  addProductQuantity = (product, quantity) => {
    const newQuantity = quantity + 1;
    this.props.addOrUpdateBillItem(product, newQuantity);
  }

  removeProductQuantity = (product, quantity) => {
    const newQuantity = quantity - 1;
    this.props.addOrUpdateBillItem(product, newQuantity);
  }

  render() {

    if(!this.props.product.id){
        return null;
    }

    if (this.props.product.out_of_stock) {
        return (
            <List.Item
                key={this.props.product.id}
                style={{ padding: '0.5em 0' }}
            >
                <List.Content>
                    <List.Description>
                        <span style={{ textDecoration: 'line-through' }}>
                        { this.props.product.name }
                        </span>
                    </List.Description>
                </List.Content>
            </List.Item>
        )
    }

    let billItemFound = null;
    console.log("Printing product info")
    console.log(this.props.product)

    console.log("Printing bill items")
    console.log(this.props.billItems)

    if (this.props.billItems && this.props.product) {
        console.log("Printing bill items on the product menu")
        console.log(this.props.billItems)
        this.props.billItems.forEach(billItem => {
            if (billItem.product.id === this.props.product.id) {
                billItemFound = billItem;
            }        
        });
    }
    
    return (
        
        <List.Item
            key={this.props.product.id}
            style={{ cursor: 'pointer', padding: '0.5em 0', borderBottom: 'solid 1px #d4d4d5' }}
        >
            <List.Content>                                
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <List.Description>
                        <span onClick={billItemFound ? () => this.addProductQuantity(billItemFound.product, billItemFound.quantity) : () => this.addProductQuantity(this.props.product, 0)}>
                            {this.props.product.name}
                        </span>
                    </List.Description>
                    <div style={{display:'flex'}}>
                        {   billItemFound ? (
                                <>
                                    {billItemFound.quantity > 0 && (
                                        <Button icon circular 
                                            color={billItemFound.quantity === 1 ? 'red' : 'grey'} 
                                            onClick={() => this.removeProductQuantity(billItemFound.product, billItemFound.quantity)} 
                                            style={{display: 'flex', justifyContent: 'center', alignItems:'center', padding: '0.5em'}}
                                        >
                                            <Icon name="minus" size='tiny'/>
                                        </Button>
                                    )}

                                    {billItemFound.quantity > 0 && (
                                        <span style={{margin:'0 0.5em'}}>{billItemFound.quantity}</span>
                                    )}
                                      
                                    <Button icon circular color="blue" onClick={() => this.addProductQuantity(billItemFound.product, billItemFound.quantity)} style={{display: 'flex', justifyContent: 'center', alignItems:'center', padding: '0.5em'}}>
                                        <Icon name="plus" size='tiny' />
                                    </Button>
                                </>
                            ) : (
                                <>                                
                                    <Button icon circular color="blue" onClick={() => this.addProductQuantity(this.props.product, 0)} style={{display: 'flex', justifyContent: 'center', alignItems:'center', padding: '0.5em'}}>
                                        <Icon name="plus" size='tiny' />
                                    </Button>
                                </>
                            )
                        }                        
                    </div>
                </div>
            </List.Content>
        </List.Item>
          
        
    )
  }
}

export default withRouter(MicroBillProductMenu);