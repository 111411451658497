import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Button, Card, Statistic } from 'semantic-ui-react';
import { addDays, subDays, format, isTomorrow, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';
import Header from 'components/Header';
import API from 'utils/api';
import formatter from 'utils/formatter';
import MicroExpenseRow from './ui/MicroExpenseRow';

class MicroExpensesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      expenses: []
    };
  }

  componentDidMount() {
    this.getExpenses();
  }

  getExpenses = () => {
    // Aquí iría la llamada a la API para obtener los gastos
    // Por ahora, usaremos datos de ejemplo
    this.setState({
      expenses: [
        { id: 1, name: 'Compra de insumos', amount: 1000 },
        { id: 2, name: 'Pago de servicios', amount: 500 }
      ]
    });
  }

  goToPrevDay = () => {
    const date = this.state.date;
    const prevDate = subDays(date, 1);
    this.setState({ date: prevDate }, this.getBills)
  }

  goToNextDay = () => {
    const date = this.state.date;
    const nextDate = addDays(date, 1);
    this.setState({ date: nextDate }, this.getBills)
  }

  getHeaderText = () => {
    return format(this.state.date, "dd '/' MMM '/' yyyy", { locale: es });
  }

  getSubheaderText = () => {
    const today = new Date();
    
    if (
      this.state.date.getDate() === today.getDate() &&
      this.state.date.getMonth() === today.getMonth() &&
      this.state.date.getFullYear() === today.getFullYear()
    ) {
      return 'HOY';
    }

    const diff = differenceInDays(this.state.date, today);
    let text = '';

    if (diff === -1) {
      text = 'AYER';
    } else {
      text = `HACE ${-diff} DIAS`;
    }
    
    return text
  }

  render() {
    const date = this.state.date;
    const nextDate = addDays(date, 1);

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            <Button size="tiny" circular icon onClick={this.goToPrevDay}>
              <Icon name="chevron left" />
            </Button>
          </span>

          <div style={{textAlign:'center'}}>
            <Header text={ this.getHeaderText().toUpperCase() } subtitle={ this.getSubheaderText() } />
          </div>
                  
          <span>
            <Button size="tiny" disabled={isTomorrow(nextDate)} circular icon onClick={this.goToNextDay}>
              <Icon name="chevron right" />
            </Button>
          </span>
        </div>
        
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
          <Header text="Gastos" subtitle="Administra los gastos de tu negocio"/>
          <Button circular icon primary as={Link} to="/micro/expenses/new">
            <Icon name="plus" />
          </Button> 
        </div>
  
        <div>
          {this.state.expenses.map((expense) => (
            <MicroExpenseRow key={expense.id} expense={expense} />
          ))}
        </div>
      </div>
    )
  }
}

export default withRouter(MicroExpensesPage);
