import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

function SubmitButton({ children, onClick, style, enabled, warning=false }) {
  const [submitting, setSubmitting] = useState(false);

  const handleClick = () => {
    setSubmitting(true);
    onClick();
  }

  const primary = !warning;

  if (!enabled) {
    return (
      <Button type='submit' primary={primary} negative={warning} disabled style={style}>
        { children }
      </Button>
    );
  }

  if (submitting) {
    return (
      <Button type='submit' primary={primary} negative={warning} disabled style={style}>
        <Icon name="spinner" loading />
      </Button>
    );
  }

  // default
  return (
    <Button type='submit' primary={primary} negative={warning} onClick={handleClick} style={style}>
      { children }
    </Button>
  )
}

export default SubmitButton;