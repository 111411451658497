import React from 'react';
import { Card, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import formatter from 'utils/formatter';

const MicroExpenseRow = ({ expense }) => {
  const handleDelete = () => {
    // Aquí iría la lógica para eliminar el gasto
    console.log('Eliminar gasto:', expense.id);
  };

  return (
    <Card fluid>
      <Card.Content>
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <Button icon as={Link} to={`/micro/expenses/edit/${expense.id}`} size='mini'>
            <Icon name='edit' />
          </Button>
          <Button icon onClick={handleDelete} size='mini'>
            <Icon name='trash' />
          </Button>
        </div>
        <Card.Header>{expense.name}</Card.Header>
        <Card.Description>
          {formatter.currency(expense.amount)}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default MicroExpenseRow;
