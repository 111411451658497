import React from 'react';
import API from 'utils/api';
import Header from 'components/Header';
import { loadStripe } from '@stripe/stripe-js';
import { Divider } from 'semantic-ui-react';

function MicroAccountPage() {
  const [userInformation, setUserInformation] = React.useState(null);
  const [stripeClient, setStripeClient] = React.useState(null);

  React.useEffect(() => {
    attemptLoadStripe();
    API.getMicroAccount()
      .then((response) => {
        console.log(response.data);
        setUserInformation(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const attemptLoadStripe = async () => {
    console.log("Loading stripe into state")
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    console.log("Stripe: ")
    console.log(stripe)
    console.log("Setting stripe in state")
    setStripeClient(stripe)
  }

  const redirectToStripeCheckout = () => {
    const params = {
      planType: 'basic'
    }

    console.log("About to call api to generate stripe checkout id")
    API.generateStripeCheckoutSessionId(params)
      .then(response => {
        console.log(response.data);
        const stripe_checkout_session_id = response.data.checkout_session_id;
        console.log("Stripe checkout id:" + stripe_checkout_session_id)

        console.log("Using stripe client to redirectToCheckout method")
        stripeClient.redirectToCheckout({
          sessionId: stripe_checkout_session_id,
        })
      })
      .catch(error => {
        console.error(error);
      });
  };

  if (!userInformation || !userInformation.created_at) {
    return <div>Loading...</div>;
  }

  // Calculate remaining days of trial
  const trialDuration = 15; // 15 days
  console.log("UserInformation.created_at: " + userInformation.created_at)
  const createdAt = new Date(userInformation.created_at);
  console.log("Created at:" + createdAt);
  const currentDate = new Date();
  console.log("Current date: " + currentDate);
  const daysPassed = Math.floor((currentDate - createdAt) / (1000 * 60 * 60 * 24));
  console.log("Days passed: " + daysPassed);
  const remainingDays = trialDuration - daysPassed;
  console.log("Remaining days: " + remainingDays);
  // const remainingDays = 15;

  // set header
  let headerText = 'Información de la cuenta';

  return (
    <div >
        <Header text={headerText}>
            {/* Add any buttons or actions here if needed */}
        </Header>
        <p>Nombre: {userInformation.name}</p>
        <p>Email: {userInformation.email}</p>

        <div style={{ marginTop: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', position: 'relative', backgroundColor: 'white', zIndex: remainingDays <= 0 ? 5 : 0 }}>
            <h3 style={{ marginBottom: '10px' }}>Plan actual: {userInformation.plan ? userInformation.plan : 'Prueba'}</h3>
            {userInformation.is_membership_active ? (
                // Do not display anything for premium users
                <p>¡Gracias por ser un usuario del plan Basico. Tienes acceso a todas las funciones basicas de la plataforma!</p>
            ) : remainingDays > 0 ? (
                <p>Periodo de prueba termina en: {remainingDays} días</p>
            ) : (
                <>
                    <p>
                        Su periodo de prueba finalizó el: {new Date(createdAt.setDate(createdAt.getDate() + trialDuration)).toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' })}.
                    </p>
                    <p>
                        <a onClick={redirectToStripeCheckout} style={{ textDecoration: 'underline', color: 'blue' }}>Agregar forma de pago</a>
                    </p>
                </>                
            )}
        </div>

        {!userInformation.is_membership_active && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Divider style={{ margin: '2em 4em' }} />
        
                <div style={{margin: '2em 1em'}}>
                  <h5>Plan Básico: $99.00 MXN/mes</h5>
                  <p>(aprox. $4.99 USD/mes)</p>
                  <p>Incluye: Acceso a todas las funciones básicas de la plataforma.</p>
                </div>

                <button 
                  onClick={redirectToStripeCheckout}
                  style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: 'blue', color: 'white', border: 'none' }}>
                    Comprar ahora
                </button>

                <Divider style={{ margin: '2em' }} />
            </div>
        )}


    </div>
  );
}

export default MicroAccountPage;

