import React from 'react';
import { withRouter } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';
import API from 'utils/api';

class MicroCategoryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarEdicion: false,
      categoryName: props.category.name
    };
  }

  mostrarEdicion = () => {
    this.setState({
      mostrarEdicion: true,
    });
  }
  
  ocultarEdicion = () => {
    this.setState({
      mostrarEdicion: false,
    });
  }

  actualizarCategoria = () => {
    const params = {
      name: this.state.categoryName
    }
    API.updateCategoryName(this.props.category.id, params)
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCategoryName = (event) => {
    this.setState({
        categoryName: event.target.value
    })
  }

  eliminarCategoria = () => {
    //if category name is "General" then do not delete
    if(this.props.category.name === 'General'){
      alert('No se puede eliminar la categoría General');
      return;
    }

    API.deleteCategory(this.props.category.id)
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    return (
        <div>
            {
              this.state.mostrarEdicion ? (
                <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', marginBottom: '1em'}}>
                  <div className="ui input focus">
                    <input type="text" value={this.state.categoryName} onChange={this.handleCategoryName}/>
                  </div>
                  <div style={{display:'flex'}}>
                    <button className="ui blue button" style={{marginRight: '1em', padding:0}} onClick={this.actualizarCategoria}>
                      <i className='sync icon' style={{margin: '1em'}}></i>
                    </button>
                    <button className="ui red button" style={{padding:0}} onClick={this.ocultarEdicion} >
                      <i className='x icon' style={{margin: '1em'}}></i>
                    </button>
                  </div>            
                </div>
                
              ) : (
                <div style={{display:'flex', justifyContent:'space-between', alignItems: 'center', marginBottom: '1em'}}>
                  <span>
                    <strong>{this.props.category.name}</strong>
                  </span>
                  {this.props.category.name !== 'General' &&
                    <div style={{display:'flex'}}>
                      <button className="ui blue button" style={{marginRight: '1em', padding:0}} onClick={this.mostrarEdicion}>
                        <i className='edit icon' style={{margin: '1em'}}></i>
                      </button>
                      <button className="ui red button" style={{padding:0}} onClick={this.eliminarCategoria}>
                        <i className='trash icon' style={{margin: '1em'}}></i>
                      </button>
                    </div>   
                  }         
                </div>
              )
            }
            
            <Divider style={{ margin: '1em 0' }} />
          </div>
    )
  }

}

export default withRouter(MicroCategoryRow);