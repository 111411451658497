import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

export default function BillClosedEvent({ bill }) {
  if (!bill) { return null }
  
  const total = formatter.currency(bill.total);
  const formattedDate = format(new Date(bill.paid_at), 'HH:mm a', { locale: es });
  
  return (
    <List.Item>
      <div style={{ marginRight: '3px', textAlign: 'center' }}>
      <b>{total}</b> de la cuenta <Link to="">#{bill.id}</Link> / { formattedDate }
      </div>
    </List.Item>
  )
}