import React, { useState, useEffect } from 'react';
import { Card, List, Button, Icon, Input } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';
import MicroBillProductMenu from './MicroBillProductMenu';
import CancelGoButtons from 'components/CancelGoButtons';

export default function MicroSearchableMenu({ cancelFn, addOrUpdateBillItem, billItems }) {
  const [menu, setMenu] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const [input, setInput] = useState('');

  // load the updated menu
  useEffect(() => {
    API.getMicroEstablishmentProducts()
      .then((response) => {
        setMenu(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // filter menu when input changes
  useEffect(() => {
    const regex = new RegExp(input, 'i');
    const newFilteredMenu = menu.map((categoryProducts) => {
      const category = categoryProducts[0];
      
      const products = categoryProducts[1].filter((product) => {
        return regex.test(product.name)
      });

      if (products.length === 0) {
        return null
      }

      return [category, products]
    });
    const newCleanedUpMenu = newFilteredMenu.filter((item) => item !== null);
    setFilteredMenu(newCleanedUpMenu);
  }, [input, menu])
  
  return (    
    <div>
      <Header text="Menu" subtitle="Busca y agrega productos">
        <Button circular icon onClick={ cancelFn }>
          <Icon name="cancel" />
        </Button>
      </Header>
      <div>

        <Input
          fluid
          placeholder="buscar..." 
          onChange={ (e) => setInput(e.target.value) }
          value={input}
        />
        
        { (filteredMenu.length === 0) ? (
            <div>
              <p style={{ marginTop: '1em', color: 'red' }}>
                Aún no has agregado productos a tu menú.
              </p>
              <p style={{ marginTop: '1em'}}>
                Para agregar productos a tu menú <a href="/micro/products/new">haz click aquí.</a><br />O en su defecto, ve al encabezado y en el menú principal haz click en el apartado: productos. 
              </p>
            </div>
          ) : (
            <Card fluid>
              <Card.Content>
                <List divided>
                    { filteredMenu.map((categoryProducts) =>
                      <List.Item
                          key={categoryProducts[0]}
                      >
                        <List.Content style={{ margin: '1em 0' }}>
                          <List.Header>
                              { categoryProducts[0] }
                          </List.Header>
                          <List.List>
                              { categoryProducts[1].map((product) => {
                                return (
                                  <MicroBillProductMenu 
                                    product={product} 
                                    billItems={billItems} 
                                    addOrUpdateBillItem={addOrUpdateBillItem}
                                  />
                                )
                              })}
                          </List.List>
                        </List.Content>
                      </List.Item>
                    )}
                </List>
              </Card.Content>
            </Card>
          )       
        }

        <CancelGoButtons
          cancelFn={ cancelFn }
          goText='Ver Cuenta'
          goEnabled={ true }
          goFn={cancelFn}
        />
      </div>
    </div>
  )
}