import React from 'react';
import { Button } from 'semantic-ui-react';
import SubmitButton from './SubmitButton';

export default function WarningMessage({ xFunction, headerText, contentText, noFunction, noBtnText, yesFunction, yesBtnText }) {
  return (
    <div 
      class="ui negative message" 
      style={{
        position: 'absolute',
        height: '75%',
        zIndex: 1,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        top:'5em',
        left:'1em',
        right:'1em'
    }}>
      <i class="close icon" onClick={xFunction}></i>
      <div style={{display: 'block', textAlign: 'center'}}>
        <h1 class="header" style={{display: 'block!important'}}>
          {headerText}
        </h1>
        <p>{contentText}</p>
        <div style={{display: 'flex'}}>
        <Button fluid style={{ marginTop: '2em' }} onClick={noFunction}>
          {noBtnText}
        </Button>
        <Button fluid onClick={ yesFunction } className='ui red button' style={{marginTop:'2em'}}>
          {yesBtnText}
        </Button>
      </div>
      </div>
    </div>
  )
}