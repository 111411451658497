import React from 'react';
import { MessageHeader, Message } from 'semantic-ui-react'
import API from 'utils/api';

export default function BannerFreeTrial(props) {

  const [userInformation, setUserInformation] = React.useState(null);

  React.useEffect(() => {
    API.getMicroAccount()
      .then((response) => {
        console.log(response.data);
        setUserInformation(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const styles = {
    padding: '2em 1em 1em 1em',
    textAlign: 'center',
    fontSize: 'small'
  };

  if(!userInformation){
    return(
      <p>Loading user information..</p>
    )
  }

  // Calculate remaining days of trial
  const trialDuration = 15; // 15 days
  const createdAt = new Date(userInformation.created_at);
  const currentDate = new Date();
  const daysPassed = Math.floor((currentDate - createdAt) / (1000 * 60 * 60 * 24));
  const trialDaysLeft = trialDuration - daysPassed;
  // const trialDaysLeft = 15;

  if(userInformation.is_membership_active){
    return null;
  }

  return (
    <div>     
      {trialDaysLeft > 7 && (
      <Message info style={{ textAlign: 'center' }}>
        <MessageHeader>Periodo de prueba, gratis por {trialDaysLeft} días!</MessageHeader>
        <p>Disfrutalo, esperemos que te guste!</p>
      </Message>
      )}

      {trialDaysLeft > 3 && trialDaysLeft <= 7 && (
        <Message warning style={{ textAlign: 'center' }}>
          <MessageHeader>Aún te quedan {trialDaysLeft} días gratis</MessageHeader>
          <p>Tu periodo gratuito termina pronto, sigue disfrutando!</p>
        </Message>
      )}

      {trialDaysLeft <= 3 && trialDaysLeft > 0 && (
        <Message negative style={{ textAlign: 'center' }}>
          <MessageHeader>Sólo te quedan {trialDaysLeft} días gratis</MessageHeader>
          <p>Tu periodo gratuito termina pronto, considera adquirir una suscripción</p>
        </Message>
      )}

      {trialDaysLeft <= 0 && (
        <div style={{position: 'absolute', zIndex: 1, top: '3em', bottom: 0, width: '100%', height: '94%', display: 'inline-flex', alignItems: 'end', backgroundColor: '#fff6f6eb'}}>
          <Message negative style={{width: '100%', textAlign: 'center', padding: '3em 1em'}}>
            <MessageHeader>Finalización del Periodo de Prueba</MessageHeader>
            <p>El período de prueba ha concluido. Para seguir utilizando esta aplicación, por favor <a href='/micro/account'>actualice su método de pago</a>.</p>
          </Message>
        </div>
      )}
    </div>
  )
}