import React, { useState, useEffect } from 'react';
import { Card, List, Button, Icon, Input, Table } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';
import MicroBillProductMenu from './MicroBillProductMenu';
import CancelGoButtons from 'components/CancelGoButtons';




export default function MicroAddOrdersMenu({ cancelFn, billId, getBill }) {
  const [menu, setMenu] = useState([]);
  const [orders, setOrders] = useState([])
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const [input, setInput] = useState('');
  const [viewAddedProducts, setViewAddedProducts] = useState(false);

  const addOrders = () => {
    console.log("Sending orders to api to update bill")

    const params = {
      orders: orders.map(order => ({
        product_id: order.product.id,
        quantity: order.quantity
      }))
    };

    console.log("params:", params);

    API.addOrders(params, billId)
      .then((response) => {
        console.log("Bill added new orders")        
        cancelFn();
        getBill();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const addOrUpdateOrder = (product, quantity) => {
    console.log("Attempting to add order in menu: ", product)
    console.log("quantity:", quantity);
    const isOrderInList = orders.some(order => order.product.id === product.id);

    if(isOrderInList) {
      const updatedOrders = orders.map(order => {
        if(order.product.id === product.id) {
          return { ...order, quantity: quantity };
        }
        return order;
      }).filter(order => order.quantity !== 0);
      setOrders(updatedOrders);
    } else if(quantity > 0) {
      setOrders([...orders, { product, quantity }]);
    }
  };

  // load the updated menu
  useEffect(() => {
    API.getMicroEstablishmentProducts()
      .then((response) => {
        setMenu(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // filter menu when input changes
  useEffect(() => {
    const regex = new RegExp(input, 'i');
    const newFilteredMenu = menu.map((categoryProducts) => {
      const category = categoryProducts[0];
      
      const products = categoryProducts[1].filter((product) => {
        return regex.test(product.name)
      });

      if (products.length === 0) {
        return null
      }

      return [category, products]
    });
    const newCleanedUpMenu = newFilteredMenu.filter((item) => item !== null);
    setFilteredMenu(newCleanedUpMenu);
  }, [input, menu])

  if(viewAddedProducts) {
    return(
      <div>
        <Header text="Productos añadidos" subtitle="Confirma los productos que se agregaran a la cuenta">
        </Header>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '1em' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f9fafb', padding: '10px', border: '1px solid #ddd' }}>
            <div style={{ width: '70%', fontWeight: 'bold' }}>Product Name</div>
            <div style={{ width: '30%', fontWeight: 'bold' }}>Quantity</div>
          </div>
          {orders.map((order) => (
            <div key={order.product.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', border: '1px solid #ddd' }}>
              <div style={{ width: '70%' }}>{order.product.name}</div>
              <div style={{ width: '30%' }}>{order.quantity}</div>
            </div>
          ))}
        </div>
        
        <CancelGoButtons
          cancelFn={ () => setViewAddedProducts(false) }
          goText={"Agregar productos"}
          goEnabled={ orders.length > 0 ? true : false }
          goFn={addOrders}
        />
      </div>
    )
  }
  
  return (    
    <div>
      <Header text="Menu" subtitle="Busca y actualiza productos">
        <Button circular icon onClick={ cancelFn }>
          <Icon name="cancel" />
        </Button>
      </Header>
      <div>

        <Input
          fluid
          placeholder="buscar..." 
          onChange={ (e) => setInput(e.target.value) }
          value={input}
        />
        
        { (filteredMenu.length === 0) ? (
            <div>
              <p style={{ marginTop: '1em', color: 'red' }}>
                Aún no has agregado productos a tu menú.
              </p>
              <p style={{ marginTop: '1em'}}>
                Para agregar productos a tu menú <a href="/micro/products/new">haz click aquí.</a><br />O en su defecto, ve al encabezado y en el menú principal haz click en el apartado: productos. 
              </p>
            </div>
          ) : (
            <Card fluid>
              <Card.Content>
                <List divided>
                    { filteredMenu.map((categoryProducts) =>
                      <List.Item
                          key={categoryProducts[0]}
                      >
                        <List.Content style={{ margin: '1em 0' }}>
                          <List.Header>
                              { categoryProducts[0] }
                          </List.Header>
                          <List.List>
                              { categoryProducts[1].map((product) => {
                                return (
                                  <MicroBillProductMenu 
                                    product={product} 
                                    billItems={orders} 
                                    addOrUpdateBillItem={addOrUpdateOrder}
                                  />
                                )
                              })}
                          </List.List>
                        </List.Content>
                      </List.Item>
                    )}
                </List>
              </Card.Content>
            </Card>
          )       
        }

        {orders.length > 0 && (
          <Button
            primary
            onClick={() => setViewAddedProducts(true)}
            style={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}
          >
            Ver productos añadidos
          </Button>
        )}

        <CancelGoButtons
          cancelFn={ cancelFn }
          goText={"Agregar productos"}
          goEnabled={ orders.length > 0 ? true : false }
          goFn={addOrders}
        />
      </div>
    </div>
  )
}