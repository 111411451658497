import React from 'react';
import { Card, Statistic } from 'semantic-ui-react';
import Header from 'components/Header';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { es } from 'date-fns/locale';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MicroSalesByWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      total: 0.0
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.getMicroSalesByWeek()
      .then((response) => {
        const sales = response.data;
        
        // calculate total
        let total = 0;

        sales.forEach((sale) => {
          total += sale.total;
        });

        this.setState({ sales, total });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  weekToDate = (weekNumber, year = new Date().getFullYear()) => {
    const result = new Date(year, 0, 1);
    result.setDate(weekNumber * 7);
    return result;
  };

  render() {
    return (
      <div style={{marginTop:'1.5em'}}>
        { this.state.sales.map((sale) => {
          return (
            <Card fluid key={sale.week}>
              <Card.Content>
                <Card.Header>
                  <span style={{ color: 'darkgray' }}>
                    { format(startOfWeek(this.weekToDate(sale.week), { locale: es }), "dd 'de' MMMM", { locale: es })} - {format(endOfWeek(this.weekToDate(sale.week), { locale: es }), "dd 'de' MMMM", { locale: es }) }
                  </span>
                </Card.Header>
                <Card.Description>
                  <Statistic.Group size="tiny">
                    <Statistic>
                      <Statistic.Value>{ formatter.currency(sale.total) }</Statistic.Value>
                    </Statistic>
                  </Statistic.Group>
                </Card.Description>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    );
  }
}

export default MicroSalesByWeek;