import React from 'react';
import { Card, Statistic } from 'semantic-ui-react';
import Header from 'components/Header';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MicroSalesByTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      total: 0.0
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.getMicroSalesByMonth()
      .then((response) => {
        const sales = response.data;
        
        // calculate total
        let total = 0;

        sales.forEach((sale) => {
          total += sale.total;
        });

        this.setState({ sales, total });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (      
      <div style={{marginTop:'1.5em', textAlign:'center'}}>        
        <Statistic size="small">
          <Statistic.Value>{ formatter.currency(this.state.total) }</Statistic.Value>
          <Statistic.Label>Total de todas las ventas</Statistic.Label>
        </Statistic>
      </div>
    );
  }
}

export default MicroSalesByTotal;