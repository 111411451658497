import React from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import authHelpers from 'utils/authHelpers';

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const user = this.props.match.params.user;
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const token = query.tkn;
    const name = query.name;
    const channel_id = query.chid;
    
    if (user && token) {
      authHelpers.login(user, token, name, channel_id);
      
      switch(user) {
        case 'micro':
          this.props.history.push('/micro/bills');
          break;
        case 'cashier':
          this.props.history.push('/cashier/orders');
          break;
        case 'waiter':
          this.props.history.push('/waiter/bills');
          break;
        case 'dancer':
          this.props.history.push('/dancer/orders');
          break;
        case 'chef':
            this.props.history.push('/chef/orders');
            break;
        case 'owner':
          this.props.history.push('/owner/sales');
          break;
        case 'manager':
          this.props.history.push('/manager/products');
          break;
        case 'referrer':
          this.props.history.push('/referrer/commissions');
          break;
      }
    }
  }
  
  render() {
    return null
  }
}

export default withRouter(AuthPage);