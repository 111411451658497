import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, MenuMenu, MenuHeader, Icon } from 'semantic-ui-react';

const Nav = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeItem, setActiveItem] = useState('Cuentas abiertas');
  const history = useHistory();

  const handleItemClick = (e, { name, url }) => {
    setShowMenu(false);
    setActiveItem(name);
    history.push(url);
  };

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      <Menu attached borderless compact inverted size="small" color="blue">
        <Menu.Item position='right'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '1em' }}>
              <a href="/micro/bills" style={{ textDecoration: 'none', color: 'white' }}>RESTAURANTERO</a>
            </span>
            <Icon
              name={showMenu ? 'close' : 'bars'}
              size="large"
              style={{ cursor: 'pointer' }}
              onClick={toggleShowMenu}
            />
          </div>
        </Menu.Item>
      </Menu>
      {showMenu && (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'fixed', zIndex: 1, width: '100%', height: '100%', backgroundColor: '#ffffffa6'}} onClick={toggleShowMenu}>
            <Menu vertical style={{ position: 'fixed', right: 0, textAlign: 'right', width: '60%', borderRadius: '0 0 0 0.28571429rem'}}>
              {Object.entries(links).map(([section, sectionLinks]) => (
                <MenuItem key={section}>
                  <span>{section.toUpperCase()}</span>
                  <MenuMenu>
                    {sectionLinks.map((link, i) => (
                      <MenuItem
                        style={{ fontSize: '1em', padding: '1em'}}
                        key={i}
                        name={link.name}
                        active={activeItem === link.name}
                        onClick={(e) => handleItemClick(e, link)}
                      >
                        {link.name}
                      </MenuItem>
                    ))}
                  </MenuMenu>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;