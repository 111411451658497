const formatter = {
  currency: (amount) => {
    const options = {
      style: 'currency',
      currency: 'USD'
    };
    
    return new Intl.NumberFormat('en-US', options).format(amount);
  }
}

export default formatter;