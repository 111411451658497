import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from 'components/Header';
import { Button, Icon } from 'semantic-ui-react';
import MicroOpenBillCard from './ui/MicroOpenBillCard';
import API from 'utils/api';

class MicroBillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: []
    };
  }

  componentDidMount() {
    this.getBills();
  }

  createBill = () => {    
    const params = {
      orders: []	
    };

    API.createMicroBill(params)
      .then((response) => {
        this.props.history.push('/micro/bills');
        this.getBills();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBills = () => {
    API.getMicroBills()
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createBill = () => {
    const params = {};

    API.createMicroBill(params)
      .then((response) => {
        this.getBills();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    if (!this.state.bills) { return null }

    // set header
    let headerText = 'Sin cuentas abiertas';

    if (this.state.bills.length === 1) {
      headerText = '1 cuenta abierta';
    }

    if (this.state.bills.length > 1) {
      headerText = `${this.state.bills.length} cuentas abiertas`;
    }
    
    return (
      <div>
        <Header text={headerText}>
          <Button
            fluid
            circular
            primary
            icon
            onClick={this.createBill}
          >
            <Icon name='plus' />
            <span style={{marginLeft:'0.5em'}}>Cuenta</span>
          </Button>
        </Header>

        <div>
          { this.state.bills.map((bill) => {
            return (
              <MicroOpenBillCard bill={bill} key={bill.id} />
            )
          })}
        </div>

        
      </div>
    )
  }
}

export default withRouter(MicroBillsPage);