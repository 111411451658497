import React from 'react';
import MicroProductCategoryForm from 'pages/micro/ui/MicroProductCategoryForm';

const MicroProductCategoryPage = () => {
    
    return (
        <div style={{ marginTop: '1rem'}}>
            <MicroProductCategoryForm />
        </div>
    );
};

export default MicroProductCategoryPage;