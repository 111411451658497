import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import API from 'utils/api';
import authHelpers from 'utils/authHelpers';
import { Divider } from 'semantic-ui-react';

export default function LoginButtons(props) { 
  const history = useHistory(); 
  
  const handleLogin = (credentialResponse) => {
    const params = { token: credentialResponse.credential };
    API.signupOrLoginWithGoogle(params)
      .then(response => {
        const token = response.data.token;
        authHelpers.login(token);
        history.push('/micro/bills');
      })
      .catch(error => {
        console.error(error);
      });
  };
  
  useEffect(() => {
    const adjustGoogleButton = () => {
      const iframe = document.querySelector('iframe[src^="https://accounts.google.com/gsi/button"]');
      if (iframe) {
        iframe.style.width = '260px';
        iframe.style.height = '50px';
      }
    };

    // Intenta ajustar inmediatamente y también después de un breve retraso
    adjustGoogleButton();
    const timeoutId = setTimeout(adjustGoogleButton, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  
  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>        
        <div style={{width: '100%', display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <GoogleLogin
            logo_alignment='center'
            text='signin_with'
            locale='es'
            size="large"
            theme="outline"
            shape="rectangular"
            onSuccess={handleLogin}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      </GoogleOAuthProvider>
    </div>
  )
}