import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { List, Card, Icon, Button } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';
import formatter from 'utils/formatter';

class MicroProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
  }
  
  componentDidMount() {
    this.getProducts();
  }
  
  getProducts = () => {
    API.getMicroEstablishmentProducts()
      .then((response) => {
        this.setState({
          menu: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    if (this.state.menu.length === 0) {
      return (
        <div>
          <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
            <Header text="Productos" subtitle="Agrega productos a tu menú" />
            <Button circular icon primary as={Link} to="/micro/products/new">
              <Icon name="plus" />
            </Button>
          </div>
          <p style={{ marginTop: '1em', color: 'red' }}>
            Aún no has agregado productos a tu menú.
          </p>
        </div>
      )
    }

    let headerText = 'Productossss';

    if (this.state.menu.length === 1) {
      headerText = `1 producto disponible`;
    } else {
      headerText = `${this.state.menu.length} productos disponibles`;
    }
    
    return (
      <div style={{ marginTop: '2em' }}>
        <Header text="Productos" subtitle="Agrega productos a tu menú"/>
    
        <Header text={headerText} subtitle="productos">
          <Button circular icon primary as={Link} to="/micro/products/new">
            <Icon name="plus" />
          </Button>
        </Header>
    
        {this.state.menu.map((categoryProducts, index) => (
          <Card fluid key={index}>
            <Card.Content>
              <Card.Header>{categoryProducts[0]}</Card.Header>
              <Card.Description>
                <List divided>
                  {categoryProducts[1].map((product) => (
                    <List.Item
                      key={product.id}
                      style={{ cursor: 'pointer', padding: '1em 0' }}
                      onClick={() => this.props.history.push(`/micro/products/${product.id}`)}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '0.5em', fontSize: '18px', textDecoration: product.out_of_stock ? 'line-through' : 'none' }}>
                          {product.out_of_stock ? (
                            <Icon name="circle" color="red" />
                          ) : (
                            <Icon name="check circle" color="green" />
                          )}
    
                          <span>
                            {product.name}
                          </span>
                        </div>
                        <div style={{ marginLeft: '1em', fontSize: '16px' }}>
                          {formatter.currency(product.price)}
                        </div>
                      </div>
                      
                      {product.desc && (
                        <div>
                          {product.desc}
                        </div>
                      )}
                    </List.Item>
                  ))}
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        ))}
      </div>
    )
  }
}
export default withRouter(MicroProductsPage);