import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';

class MicroExpensesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      amount: '',
      isLoading: false,
      error: null
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { name, amount } = this.state;
    this.setState({ isLoading: true, error: null });

    API.createMicroExpense({ name, amount: parseFloat(amount) })
      .then(response => {
        console.log('Gasto guardado:', response.data);
        this.props.history.push('/micro/expenses');
      })
      .catch(error => {
        console.error('Error al guardar el gasto:', error);
        this.setState({ error: 'Hubo un error al guardar el gasto. Por favor, inténtalo de nuevo.' });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { name, amount, isLoading, error } = this.state;

    return (
      <div>
        <Header text="Nuevo Gasto" subtitle="Agrega un nuevo gasto a tu registro" />
        <Form onSubmit={this.handleSubmit} loading={isLoading} error={!!error}>
          <Form.Input
            label="Nombre del gasto"
            placeholder="Ej: Compra de insumos"
            name="name"
            value={name}
            onChange={this.handleChange}
            required
          />
          <Form.Input
            label="Monto"
            placeholder="Ej: 1000"
            name="amount"
            type="number"
            value={amount}
            onChange={this.handleChange}
            required
          />
          {error && <Form.Field error content={error} />}
          <div style={{display:'flex', alignItems:'center', marginTop:'3em'}}>
            <Button secondary style={{width:"30%"}} onClick={() => this.props.history.push('/micro/expenses')}>Atrás</Button>
            <Button primary style={{width:"70%"}} type="submit">Guardar Gasto</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(MicroExpensesForm);
