import React from 'react';
import { Icon, Image, Divider } from 'semantic-ui-react';
import businesOwnerImage from 'images/business-owner-large.png';
import LoginButtons from 'components/LoginButtons';
import NavPublic from 'ui/NavPublic';
import MoreInfo from 'components/MoreInfo';
import infoSegments from 'components/MoreInfo';

class HomePage extends React.Component {
  state = {
    showMoreInfo: false
  }

  toggleMoreInfo = () => {
    this.setState({ showMoreInfo: !this.state.showMoreInfo });
  }

  render() {
    return (
      <div style={{ textAlign: 'center', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
        <NavPublic />
        <div style={{ marginBottom: '1em' }}>
          <Image src={businesOwnerImage} fluid />
          <div style={{ margin: '1em 0' }}>
            <h1 style={{ marginBottom: '2.5px' }}>
              Restaurantero
            </h1>
            <p style={{ color: 'gray' }}>
              Pensado para los emprendedores<br/>
              que operan solos su negocio de comida.
            </p>
          </div>
        </div>

        <Divider style={{ margin: '2em 4em' }} />
        
        <div style={{margin: '2em 1em'}}>
          <h5 style={{margin: '0.5em 1em'}}>Lleva tus finanzas bajo control...</h5>
          <h5 style={{margin: '0.5em 1em'}}>Crea tus propias cuentas...</h5>
          <h5 style={{margin: '0.5em 1em'}}>Crea tus propias categorías y productos...</h5>
        </div>

        {!this.state.showMoreInfo && 
          <a style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0}} onClick={this.toggleMoreInfo}>
            Mostrar más información<Icon style={{marginLeft: '0.5em'}} name='angle down' />
          </a>
        }

        {this.state.showMoreInfo && <MoreInfo />}

        {this.state.showMoreInfo && 
          <a style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0}} onClick={this.toggleMoreInfo}>
            Mostrar menos información<Icon style={{marginLeft: '0.5em'}} name='angle up' />
          </a>
        }

        <Divider style={{ margin: '2em 4em' }} />

        <LoginButtons />
      </div>
    )
  }
}

export default HomePage;