import React from 'react';
import { Button } from 'semantic-ui-react';
import SubmitButton from './SubmitButton';

export default function CancelGoButtons({ cancelText="cancelar", cancelFn, goText, goFn, goEnabled, warning=false }) {
  return (
    <div style={{ display: 'flex' }}>
      <Button onClick={cancelFn}>
        { cancelText }
      </Button>
      
      <SubmitButton style={{ flexGrow: 2 }} onClick={goFn} enabled={goEnabled} warning={warning}>
        { goText }
      </SubmitButton>
    </div>
  )
}