import React from 'react';
import { Divider } from 'semantic-ui-react';

export default function MoreInfo() {
  const infoSegments = [
    {
      hTxt:'Lleva tus finanzas bajo control', 
      pTxt: 'Todas las cuentas cerradas exitosamente se guardaran en un historial, del cual obtendras un reporte semanal y mensual de todas las ventas en ese periodo de tiempo.', 
      imgSrc: require('../images/chefs.gif')
    },
    {
      hTxt:'Crea tus propias cuentas', 
      pTxt: 'Crea tus propias cuentas y lleva un control  de los productos que te van oredenando tus clientes.', 
      imgSrc: require('../images/chefs.gif') 
    },
    {
      hTxt:'Crea tus propias categorías y productos', 
      pTxt: 'Crea categorías que podrás asignar a tus productos, productos que también podrás crear y guardar.', 
      imgSrc: require('../images/chefs.gif')
    }    
  ]

  return (
    <div style={{padding: '1em'}}>

      <Divider style={{ margin: '0 4em' }} />

      {infoSegments.map( (infoSegment) =>
        <>
          <h3>{infoSegment.hTxt}</h3>
          <p>{infoSegment.pTxt}</p>
          <img style={{marginTop: '1em'}} src={infoSegment.imgSrc} />
          <Divider style={{ margin: '3em 3em' }} />
        </> 
      )}
    </div>
  )
}