import React from 'react';
import { Grid, Card, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import PoweredBy from 'ui/PoweredBy';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MenuPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      uuid: '',
      name: '',
      menu: []
    };
  }
  
  componentDidMount() {
    const query = qs.parse(this.props.match.params, { ignoreQueryPrefix: true });
    const uuid = query.uuid;
    
    this.setState({ uuid }, () => {
      this.getData();
    });
  }

  getData = () => {
    if (!this.state.uuid) { return }

    API.getEstablishmentMenu(this.state.uuid)
      .then((response) => {
        this.setState({
          name: response.data.name,
          menu: response.data.menu
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    if (!this.state.menu) { return null }

    return (
      <div style={{ paddingBottom: '2em', margin: '1em' }}>
        <h1 style={{ marginBottom: 0, marginTop: '1em', textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}>
          { this.state.name }
        </h1>

        <h2 style={{ marginTop: 0, marginBottom: '2em', textAlign: 'center', color: 'gray', fontWeight: 'normal', fontSize: '1.5em' }}>
          menú
        </h2>

        { this.state.menu.map((productsByCategory, index) =>
          <Card fluid key={index}>
            <Card.Content>
              <Card.Header style={{ textAlign: 'left', textTransform: 'uppercase', marginBottom: '1em', marginTop: '0.5em' }}>
                { productsByCategory[0] }
              </Card.Header>
              <Card.Description>
                { productsByCategory[1].map((product) =>
                  <Grid key={product.id}>
                    <Grid.Row verticalAlign="top">
                      <Grid.Column width={11}>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>
                          { product.name }
                        </h4>
                        <p style={{ color: 'gray' }}>
                          { product.desc }
                        </p>
                      </Grid.Column>
                      <Grid.Column width={5} textAlign="right">
                        { product.out_of_stock ? (
                          <Label>agotado</Label>
                        ) : (
                          <span style={{ fontSize: 'small', lineHeight: '18px', fontWeight: 'normal' }}>
                            { formatter.currency(product.price) }
                          </span>
                        )}
                      </Grid.Column>  
                    </Grid.Row>
                  </Grid>
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        )}
        
        <PoweredBy />
      </div>
    )
  }
}

export default withRouter(MenuPage);