import React from 'react';
import { MessageHeader, Message } from 'semantic-ui-react'
import API from 'utils/api';

export default function PoweredBy(props) {

  const styles = {
    padding: '2em 1em 1em 1em',
    textAlign: 'center',
    fontSize: 'small'
  };

  return (
    <div>
      <div style={styles}>
        powered by
        {' '}
        <a
          href="https://restaurantero.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          restaurantero.io
        </a>
      </div>      
    </div>
  )
}