import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Label, Button, Icon } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';
import formatter from 'utils/formatter';
import MicroProductForm from './ui/MicroProductForm';
import WarningMessage from 'components/WarningMessage';

class MicroProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
      product: null,
      showArchiveAlert: false
    };
  }
  
  componentDidMount() {
    const productId = parseInt(this.props.match.params.productId);
    this.setState({ productId }, this.getProduct);
  }

  getProduct = () => {
    API.getMicroEstablishmentProduct(this.state.productId)
      .then((response) => {
        this.setState({ product: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleProductAvailability = () => {
    API.toggleMicroProductAvailability(this.state.productId)
      .then((response) => {
        this.getProduct();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  showArchiveAlert = () => {
    this.setState({showArchiveAlert: true})
  }

  hideArchiveAlert = () => {
    this.setState({showArchiveAlert: false})
  }

  archiveProduct = () => {
    API.archiveMicroProduct(this.state.productId)
      .then((response) => {
        // redirect to products page and reload page
        this.props.history.push('/micro/products');
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (!this.state.product) { return null }
    
    return (
      <div>
        { // this shows the Archive alert
          this.state.showArchiveAlert && (
            <WarningMessage 
              xFunction={this.hideArchiveAlert}
              headerText={"Alerta!"}
              contentText={"Si archivas este producto ya no podrás volver a usarlo. Estás seguro que deseas archivarlo?"}
              noFunction={this.hideArchiveAlert}
              noBtnText={"No, regresar"}
              yesFunction={this.archiveProduct}
              yesBtnText={"Sí, archivar"}
            />
          )
        }
        

        <Header text={`${this.state.product.name}`} subtitle={`${this.state.product.product_category.name}`}> </Header>

        <div style={{ marginBottom: '1em', fontSize: '24px' }}>
          { formatter.currency(this.state.product.price) }
        </div>

        <div style={{ marginBottom: '1.5em', display: 'flex', justifyContent: 'space-between' }}>
          { this.state.product.out_of_stock ? (
            <Label color="red" style={{ marginRight: '0.5em' }}>
              no disponible
            </Label>
          ) : (
            <Label color="green" style={{ marginRight: '0.5em' }}>
              disponible
            </Label>
          )}

          <Button icon circular onClick={this.toggleProductAvailability} size="mini">
            { this.state.product.out_of_stock ? (
              <Icon name="check" />
            ) : (
              <Icon name="close" />
            )}
          </Button>
        </div>
        
        <p style={{ fontSize: '16px' }}>
          { this.state.product.desc ? this.state.product.desc : 'Sin descripción.' }
        </p>
        
        <div style={{display: 'flex'}}>
          <Button fluid style={{ marginTop: '2em' }} as={Link} to="/micro/products">
            regresar
          </Button>
          <Button fluid onClick={ this.showArchiveAlert } className='ui red button' style={{marginTop:'2em'}}>
            <Icon name="download" />Archivar
          </Button>
        </div>
      </div>
    )
  }
}

export default withRouter(MicroProductPage);